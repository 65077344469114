import React, { useState, useEffect } from "react";


//Components
import Loader from "../Loader/Loader";
import { Container } from "@hybris-software/ui-kit";
import BodyTerm from "./BodyTerm/BodyTerm";
import HeroCenter from "./HeroCenter/HeroCenter";

//Styles
import Style from "./PrivacyView.module.css";

const ContanctView = () => {

  const [loading, setLoading] = useState(true);

  if(loading){
    document.body.style.overflow = "hidden";
  }else{
    document.body.style.overflow = "unset";
  }
  
    useEffect(() => {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }, []);

  document.title = "Pulse World | Privacy Policy";
  return (
    <>
      {loading && <Loader />}

      <Container className={Style.container}>
        <section className={Style.heroCenter}>
          <HeroCenter />
        </section>
      </Container>
      <section>
        <BodyTerm className={Style.bodyTerm} />
      </section>
    </>
  );
};

export default ContanctView;
