import { Container } from "@hybris-software/ui-kit";
import React, { useState, useEffect } from "react";

//Components
import Loader from "../Loader/Loader";
import Evolution from "./Components/Evolution/Evolution";
import HeroCenter from "./Components/HeroCenter/HeroCenter";
import CreatingMetaverse from "./Components/CreatingMetaverse/CreatingMetaverse";
import TradeMechanics from "./Components/TradeMechanics/TradeMechanics";
import PulseFi from "./Components/PulseFi/PulseFi";
import SecureEarn from "./Components/SecureEarn/SecureEarn";
import ContactUs from "../../Components/UIKit/ContactUs/ContactUs";
// import NextLevel from "./Components/NextLevel/NextLevel";

//Styles
import Style from "./HomePageView.module.css";
import Unleash from "./Components/Unleash/Unleash";

const HomePageView = () => {
  const [loading, setLoading] = useState(true);

if(loading){
  document.body.style.overflow = "hidden";
}else{
  document.body.style.overflow = "unset";
}

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  document.title = "Pulse World | Home";
  return (
    <>
      {loading && <Loader />}
      <main>
      <Container className={Style.container}>
        <section className={Style.heroCenter}>
          <HeroCenter />
        </section>
      </Container>
      <section className={Style.pulseFi}>
        <PulseFi />
      </section>
      <section className={Style.trade}>
        <TradeMechanics />
      </section>
      <section className={Style.unleash}>
        <Unleash />
      </section>
      <section className={Style.secureEarn}>
        <SecureEarn />
      </section>
      <section className={Style.creatingMetaverse}>
        <CreatingMetaverse />
      </section>
      {/* <section className={Style.nextLevel}>
        <NextLevel />
      </section> */}
      <section className={Style.evolution}>
        <Evolution />
      </section>
      <section className={Style.contactUs}>
        <ContactUs />
      </section>
      </main>
    </>
  );
};

export default HomePageView;
