// Libraries
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@hybris-software/ui-kit";

import MainLayout from "./Layouts/MainLayout/MainLayout";
import EcosystemView from "./Views/EcosystemView/EcosystemView";
import HomePageView from "./Views/HomepageView/HomePageView";
import AboutView from "./Views/AboutView/AboutView";
import TermsView from "./Views/TermsView/TermsView";
import ContanctView from "./Views/ContactView.jsx/ContanctView";
import PrivacyView from "./Views/PrivacyView/PrivacyView";
import Page404 from "./Views/Page404/Page404";

//Styles
import Theme from "./Assets/css/Theme.module.css";

function App() {
  return (
    <ThemeProvider
      theme={{
        row: {
          columnGap: {
            horizontal: {
              xs: 60,
              sm: 60,
              md: 60,
              lg: 60,
              xl: 60,
              xxl: 60,
            },
            vertical: {
              xs: 60,
              sm: 60,
              md: 60,
              lg: 60,
              xl: 60,
              xxl: 60,
            },
          },
        },

        container: {
          containerClassName: Theme.container,
        },
        button: {
          buttonClassName: Theme.buttonStyle,
        },

        inputField: {
          baseClassName: Theme.inputFieldStyle,
          errorClassName: Theme.inputFieldErrorStyle,
          successClassName: Theme.inputFieldSuccessStyle,
          labelClassName: Theme.inputFieldLabelStyle,
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          {/* Add main route here */}
          <Route path="/" element={<MainLayout />}>
            <Route path="" element={<HomePageView />} />
            <Route path="ecosystem" element={<EcosystemView />} />
            <Route path="about" element={<AboutView />} />
            <Route path="contact" element={<ContanctView />} />
            <Route path="terms-and-conditions" element={<TermsView />} />
            <Route path="privacy-policy" element={<PrivacyView />} />
          </Route>
          <Route path="*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
