import React from "react";

//Components
import { Button } from "@hybris-software/ui-kit";

//Styles
import Style from "./Disclaimer.module.css";

const Disclaimer = ({ handleClick }) => {
  return (
    <div>
      <>
        <div className={Style.backdrop}></div>
        <div className={Style.modal}>
          <h3>Disclaimer</h3>
          <div className={Style.content}>
            <h4>Last Updated: 27 October 2022</h4>
            <h4>A. Platform Risks</h4>
            <p>
              There is a risk that We may be temporarily or permanently not able
              to provide access to the Platform and thus also to the Services.
              The Platform is provided “as is” and “as available”. We and our
              third-party partners, expressly disclaim all representations or
              warranties of any kind, whether express, implied, statutory, or
              otherwise about the Platform and Services, including, without
              limitation, any implied warranties of merchantability, fitness for
              a particular purpose, non-infringement or course of performance.
            </p>
            <div style={{color: "#dddddd"}}>
              We do not warrant that
              <ul className={Style.order}>
                <li>
                  {" "}
                 (i) the Platform will function uninterrupted, secure or available
                  at any particular time or location, or will be error-free or
                  free of harmful components;
                </li>

                <li>(ii) any errors or defects will be corrected;</li>

                <li>
                  {" "}
                  (iii) the Platform is free of viruses or other harmful components;
                </li>

                <li>
                  {" "}
                  (iv) any content and data, including Your data, will be secure or
                  not otherwise lost or damaged;
                </li>

                <li>
                  {" "}
                  (v) the results that may be obtained from the use of the Services
                  will be accurate or reliable; or
                </li>

                <li>
                  {" "}
                  (vi) the results of using the Platform will meet Your requirements
                  or expectations.
                </li>
              </ul>
            </div>
            <h4>B. Digital Asset and Blockchain Risks</h4>
            <p>
              By accessing or using our Platform and/or Services, You are
              voluntarily choosing to engage in sophisticated and risky asset
              exchanges and transactions. You are further acknowledging that You
              are aware of the many risks associated with the use of these
              Services and with engaging in transactions in cryptocurrencies,
              including, but not limited to, risks of financial loss, technology
              glitches (including, but not limited to, problems with the
              blockchain technology), and hacking. We work hard to provide
              state-of-the-art systems and security. Nonetheless, certain issues
              and risks are unavoidable, and if such issues or problems arise in
              connection with Your use of Our Platform, including technical
              difficulties with depositing or trading cryptocurrencies, it may
              take days, weeks, or months to resolve, and some issues may not be
              resolved at all. By agreeing to these Terms, You acknowledge that
              We are not responsible for the aforementioned risks, and You
              voluntarily assume and accept such risks in deciding to engage in
              cryptocurrency transactions on Our Platform.
            </p>
            <p>
              You assume the risks of engaging in transactions that rely on
              smart contracts and other experimental technology. Transactions
              may rely on smart contracts stored on various blockchains,
              cryptographic tokens generated by the smart contracts, and other
              nascent software, applications and systems that interact with
              blockchain-based networks. These technologies are experimental,
              speculative, inherently risky, and subject to change. Among other
              risks, bugs, malfunctions, cyberattacks, or changes to the
              applicable blockchain (e.g., forks) could disrupt these
              technologies and even result in a total loss of crypto assets,
              their market value, or digital funds. You are solely responsible
              for the safekeeping of the private key associated with the
              blockchain address used to interact with the Platform. We assume
              no liability or responsibility for any such risks. If You are not
              comfortable assuming these risks, You should not access or engage
              in transactions using blockchain-based technology.
            </p>

            <p>
              You agree to the automated collection and disbursement of proceeds
              by smart contracts. You acknowledge and agree that all
              transactions accessed through the Services will be automatically
              processed using one or more blockchain-based smart contracts. By
              engaging in transactions using the Services, You acknowledge and
              consent to the automatic processing of all transactions in
              connection with using the Services. You further acknowledge and
              agree that the applicable smart contract will dictate how the
              funds of a transaction and ownership of crypto assets are
              distributed.
            </p>

            <p>
              You acknowledge the risks of using the Services. You bear sole
              responsibility for evaluating the Services before using them, and
              all transactions accessed through the Services are irreversible,
              final, and without refunds. The Services may be disabled,
              disrupted or adversely impacted as a result of sophisticated
              cyber-attacks, surges in activity, computer viruses, and/or other
              operational or technical challenges, among other things. We
              disclaim any ongoing obligation to notify You of all of the
              potential risks of using and accessing our Services. You agree to
              (defined below) accept these risks and agree that You will not
              seek to hold Us responsible for any consequent losses.
            </p>

            <p>
              You are solely responsible for the security of Your wallet. You
              understand and agree that You are solely responsible for
              maintaining the security of Your wallet. Any unauthorized access
              to Your wallet by third parties could result in the loss or theft
              of any crypto asset, or any funds held in your wallet (s). You
              understand and agree that We have no involvement in, and You will
              not hold us responsible for managing and maintaining the security
              of Your wallet. You further understand and agree that We are not
              responsible, and You will not hold us accountable, for any
              unauthorized access to Your wallet. It is Your responsibility to
              monitor Your Wallet.
            </p>

            <h4>C. Gambling Risks</h4>

            <p>
              By accessing any betting, gambling and casino (“Gambling”)
              Services on our Platform, you acknowledge that there is a high
              risk of losing your funds due to the nature of such services. You
              agree that Your use of our Gambling Services is at Your sole
              option, discretion and risk.
            </p>

            <p>
              You agree that there are various risks associated with your mental
              health and emotional stress during Gambling, which can directly or
              directly affect your life.
            </p>
          </div>
          <div className={Style.buttonContainer}>
            <Button onClick={handleClick}>I Accept</Button>
          </div>
        </div>
      </>
    </div>
  );
};

export default Disclaimer;
