import React from "react";

//Components
import { Container } from "@hybris-software/ui-kit";

//Styles
import Style from "./WhatIsPulse.module.css";

const WhatIsPulse = () => {
  return (
    <Container style={{ position: "relative" }}>
      <div className={Style.bgLines}></div>
      <div className={Style.footer}></div>
      <div className={Style.mainContainer}>
        <div className={Style.imageContainer}></div>
        <div className={Style.right}>
          <h3>
            A Blockchain Based
            <span>
              <br />
              Revolution
            </span>
            <div className={Style.pinkDots}></div>
          </h3>
          <p>
            Our is to create a true ecosystem, revolutionary and
            blockchain based, that, different from the traditional systems of
            metaverse, staking and NFT, allows users to find each other and
            socialize, creating a community of people united not only by
            interests but also by space and time, for value sharing and growth
            that is collective and, above all, real.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default WhatIsPulse;
