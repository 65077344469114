import React, { useState, useEffect } from "react";


//Components
import Loader from "../Loader/Loader";
import ContactUs from "../../Components/UIKit/ContactUs/ContactUs";
import NewWay from "./Components/NewWay/NewWay";
import WhatIsPulse from "./Components/WhatIsPulse/WhatIsPulse";
// import Tokenomics from "./Components/Tokenomics/Tokenomics";
import HeroCenter from "./Components/HeroCenter/HeroCenter";
import Gateway from "./Components/Gateway/Gateway";

import CircleServices from "./Components/CircleServices/CircleServices";
//Libraries
import { Container } from "@hybris-software/ui-kit";

//style
import Style from "./Ecosystem.module.css";

const EcosystemView = () => {

  const [loading, setLoading] = useState(true);

  if(loading){
    document.body.style.overflow = "hidden";
  }else{
    document.body.style.overflow = "unset";
  }
  
    useEffect(() => {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }, []);

  document.title = "Pulse World | Ecosystem";
  return (
    <>
    <main>
    {loading && <Loader />}
    
      <Container className={Style.container}>
        <section className={Style.heroCenter}>
          <HeroCenter />
        </section>
      </Container>
      <section className={Style.whatIs}>
        <WhatIsPulse />
      </section>
      <section className={Style.circleServices}>
        <CircleServices />
      </section>
      <section className={Style.newWay}>
        <NewWay />
      </section>
      <section className={Style.gateway}>
        <Gateway />
      </section>
      {/* <section className={Style.tokenommics}>
        <Tokenomics />
      </section> */}
      <section className={Style.contactUs}>
        <ContactUs />
      </section>
      </main>
    </>
  );
};

export default EcosystemView;
