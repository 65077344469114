import React from "react";
//Components
//images
import Signal from "../../../../Assets/images/signal.svg";
//Styles
import Style from "./Roadmap.module.css";
const data = [
  {
    date: "Starting The Project ",
    title: <></>,
  },
  {
    date: "Q3 2022 ",
    title: (
      <>
        Beta Testing Pulse Metaverse
        <br /> Start Platform Development <br />
        Whitepaper v.1.0
      </>
    ),
  },

  {
    date: "Q4 2022",
    title: (
      <>
        Event Launch at Maldives <br /> Angel and Seed Round <br /> Open Trading
        Rooms and Trading Pools <br /> Open Lounge Music Rooms <br />
        Starting Guerrilla Marketing
      </>
    ),
  },
  {
    date: "Q1 2023",
    title: (
      <>
        Launch XPL token <br />
        First CEXs Listing <br /> CMC and Coin Geko Listing <br /> Open Event
        Rooms <br /> Open Meeting Rooms <br /> Community and Personal Chat &
        Social Features
      </>
    ),
  },
  {
    date: "Q2 2023",
    title: (
      <>
        Whitepaper v. 2.0
        <br /> Starting Influencer marketing
        <br /> NFT Marketplace
        <br /> Open Gaming Rooms
      </>
    ),
  },
  {
    date: "Q3 2023",
    title: (
      <>
        Metaverse Expansion
        <br /> Launch Pulse Metaverse on BNB Chain
        <br /> DAO System
      </>
    ),
  },
];
const Roadmap = () => {
  return (
    <div className={Style.mainContainer}>
      <h3>Important Dates To Remeber</h3>
      <div className={Style.roadBox}>
        <img src={Signal} alt="" />
        <div className={Style.secondPoint}>
          <div className={Style.cards}>
            <h5> Starting The Project</h5>
          </div>
        </div>
        <div className={Style.thirdPoint}>
          <div className={Style.cards}>
            <h5> Q3 2022 </h5>
            <p>
              Beta Testing Pulse Metaverse Start Platform Development Whitepaper
              v.1.0
            </p>
          </div>
        </div>
        <div className={Style.fourthPoint}>
          <div className={Style.cards}>
            <h5> Q4 2022</h5>
            <p>
              Event Launch at Maldives <br /> Angel and Seed Round <br /> Open
              Trading Rooms and Trading Pools
              <br /> Open Lounge Music Rooms <br />
              Starting Guerrilla Marketing
            </p>
          </div>
        </div>
        <div className={Style.fifthPoint}>
          <div className={Style.cards}>
            <h5> Q1 2023</h5>
            <p>
              Launch XPL token <br />
              First CEXs Listing
              <br /> CMC and Coin Geko Listing <br />
              Open Event Rooms <br />
              Open Meeting Rooms
              <br /> Community and Personal Chat & Social Features
            </p>
          </div>
        </div>
        <div className={Style.sixthPoint}>
          <div className={Style.cards}>
            <h5> Q2 2023</h5>
            <p>
              Whitepaper v. 2.0 <br />
              Starting Influencer marketing <br />
              NFT Marketplace <br />
              Open Gaming Rooms
            </p>
          </div>
        </div>
        <div className={Style.seventhPoint}>
          <div className={Style.cards}>
            <h5> Q3 2023</h5>
            <p>
              Metaverse Expansion
              <br /> Launch Pulse Metaverse on BNB Chain
              <br /> DAO System
            </p>
          </div>
        </div>
      </div>
      <div className={Style.roadBoxMobile}>
        {data.map((card, index) => {
          return (
            <div className={Style.cards} key={index}>
              <h5> {card.date}</h5>
              <p>{card.title}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Roadmap;
