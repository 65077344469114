import React from "react";
//Components
import { Row, Col } from "@hybris-software/ui-kit";
//Sytles
import Style from "./HeroCenter.module.css";

const HeroCenter = () => {
  return (
    <div className={Style.subContainer}>
      <Row>
        <Col lg={7} className={Style.rightCol}>
          <div className={Style.textContainer}>
            <h2>Know More About Pulse World</h2>
            <h1>
              <span className={Style.stroke}>ABOUT</span> US
            </h1>
            <p>
              Our entire Team is composed of professionals from the DeFi,
              GameFi, SocialFi, Web3 and NFT fields. Their decades of experience
              enables each of them to make Pulse World a visionary yet real
              project, revolutionary yet truly impactful in the lives of the
              people who take part.
            </p>
          </div>
        </Col>

        <Col lg={5} className={Style.leftCol}></Col>
      </Row>
    </div>
  );
};

export default HeroCenter;
