import React from "react";

//Components
import { Row, Col, Container, Button } from "@hybris-software/ui-kit";

//images
import metaEclipse from "../../../../Assets/images/metaverseEclipse.png";
import walletGatewayImage from "../../../../Assets/images/walletGatewayImage.png";
import socialMediagateway from "../../../../Assets/images/socialMediagateway.png";
import musicGateway from "../../../../Assets/images/musicGateway.png";
import NFTgateway from "../../../../Assets/images/NFTgateway.png";

//icons
import order from "../../../../Assets/icons/orderIcon.svg";

//Styles
import Style from "./Gateway.module.css";

const informations = [
  {
    title: "Connecting People",
    description: "A place in the Metaverse to meet and network.",
  },
  {
    title: "Creating Value",
    description:
      "By harnessing the power of community and making the most of all the opportunities brought to bear by the metaverse and blockchain technology.",
  },
  {
    title: "XPL",
    description:
      "XPL is the native and utility token of Pulse Metaverse. The main purpose of the XPL is to allow users to govern and earn rewards the growth of the metaverse.",
  },
];

const services = [
  {
    image: metaEclipse,
    title: "Metaverse",
  },
  {
    image: walletGatewayImage,
    title: "XPL",
  },
  {
    image: socialMediagateway,
    title: "Rooms",
  },
  {
    image: musicGateway,
    title: "Music",
  },
  {
    image: NFTgateway,
    title: "Nft",
  },
];

const Gateway = () => {
  return (
    <Container className={Style.mainCotainer}>
      <Row
        columnGap={{
          horizontal: {
            xs: 0,
          },
        }}
      >
        <Col lg={5}>
          <div className={Style.leftContainer}>
            <h2>A Simple Way to Keep in Touch and Grow Value</h2>
            <div className={Style.contentContainer}>
              {informations.map((info, index) => (
                <div key={index} className={Style.content}>
                  <img src={order} alt="" />
                  <div className={Style.information}>
                    <h3>{info.title}</h3>
                    <p>{info.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className={Style.button}>
              <a
                href="https://app.pulseworld.com"
                target="_blank"
                rel="noreferrer"
              >
                <Button>Take me to Pulse Platform</Button>
              </a>
            </div>
          </div>
        </Col>
        <Col lg={7} className={Style.rightContainer}>
          <div className={Style.imageContainer}></div>
          <div className={Style.cards}>
            {services.map((el, index) => {
              return (
                <div className={Style.card} key={index}>
                  <div
                    className={Style.eclipse}
                    style={{ backgroundImage: `url(${el.image})` }}
                  ></div>
                  <div className={Style.waves}></div>
                  <p>{el.title}</p>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Gateway;
