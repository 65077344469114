import React from "react";
//Components
import { Row, Col } from "@hybris-software/ui-kit";
//images
import VR from "../../../Assets/images/VrGlasses.png";
//Sytles
import Style from "./HeroCenter.module.css";

const HeroCenter = () => {
  return (
    <div className={Style.subContainer}>
      <Row className={Style.imageBackground}>
        <Col lg={5} className={Style.leftCol}>
          <div className={Style.textContainer}>
            <h1>
              PRIVACY<span className={Style.stroke}> POLICY</span>
            </h1>
            <p>
              Take a look to our legal agreements between us and a person like
              you who wants to use that service. You must agree to abide by the
              terms of service in order to use the offered service by Pulse
              World.
            </p>
          </div>
        </Col>
        <Col lg={7} className={Style.rightCol}>
          <div className={Style.image}>
            <img src={VR} alt="" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HeroCenter;
