import React from 'react'

// Icons
import { GoAlert } from "react-icons/go";
import { AiOutlineCloseCircle } from "react-icons/ai";

// Styles
import Style from "./Alert.module.css";

const Alert = ({ setAlertPopup }) => {
    return (
        <div className={Style.alert}>
            <div className={Style.content}>
                <AiOutlineCloseCircle className={Style.close} onClick={() => setAlertPopup(false)} />
                <div className={Style.boxAlert}>
                    <GoAlert className={Style.alertIcon} />
                </div>
                <span>We are implementing the login system directly on the pulse platform. You will be able to log in again in 24/48h. An email will be sent to you with the link to reset your password.</span>
            </div>
        </div>
    )
}

export default Alert