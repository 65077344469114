import React from "react";

//Components
import { Container } from "@hybris-software/ui-kit";

//Assets
import boyHovering from "../../../../Assets/images/boyHovering.png";
import twoGuys from "../../../../Assets/images/twoGuys.png";
import VRgirl from "../../../../Assets/images/girlVR.png";

//Styles
import Style from "./TradeMechanics.module.css";

const data = [
  {
    id: 1,
    title: "Stakeholders",
    textContent:
      "Institutional partners and professionals in the cryptocurrency world, with know-how and established history behind them: these are the stakeholders put in place to implement Pulse Trading strategies.",
    image: VRgirl,
    BGColor: "#6F5BEB",
  },
  {
    id: 2,
    title: "Machine Learning & A.I.",
    textContent:
      "To do so, these stakeholders implement their strategies by applying Machine learning and A.I. to quantitative trading, resulting in trend following and working across multiple digital assets. ",
    image: twoGuys,
    BGColor: "#EBB15B",
  },
  {
    id: 3,
    title: "Analysis",
    textContent:
      "This means that all strategies deployed are implemented and constantly monitored on data analysis, market analysis, and fundamental analysis.",
    image: boyHovering,
    BGColor: "#89EB5B",
  },
];

const TradeMechanics = () => {
  return (
    <Container className={Style.mainContainer}>
      <div className={Style.dots}></div>
      <h2 className={Style.heading}>Trading Vault</h2>
      <div>
        <div className={Style.cards}>
          {data.map((el, index) => {
            return (
              <div className={Style.cardContainer} key={index}>
                <div
                  className={Style.color}
                  style={{ background: el.BGColor }}
                ></div>
                <div
                  className={Style.cardImage}
                  style={{ backgroundImage: `url(${el.image})` }}
                ></div>
                <div className={Style.cardBody}>
                  <h4>{el.title}</h4>
                  <p>{el.textContent}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default TradeMechanics;
