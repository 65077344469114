import React from "react";

//Components
import { Container, Row, Col } from "@hybris-software/ui-kit";
import { Link } from "react-router-dom";

//Icons
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube, FaLinkedin } from "react-icons/fa";

//Assets
import logo from "../../../../src/Assets/icons/logo.svg";

//Styles
import Style from "./Footer.module.css";

const Footer = () => {
  // Variables
  const d = new Date();
  let year = d.getFullYear();

  // Functions
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Container className={Style.mainContainer}>
      <Row
        className={Style.footerTop}
        columnGap={{
          horizontal: {
            xs: 10,
          },
          vertical: {
            xs: 50,
          },
        }}
      >
        <Col lg={5} md={12}>
          <div className={Style.logoContainer}>
            <img src={logo} alt="" />
          </div>
          <p>
            Pulse World is a revolutionary metaverse allow user to access all
            the benefit of blockchain technlogy through DeFi, GameFi, SocialFi,
            Web3, and NFTs and puts them under one big world.
          </p>
        </Col>
        <Col lg={2} md={4} xs={6}>
          <h5>Site</h5>
          <ul>
            <li>
              <Link to="/" onClick={scrollToTop}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/ecosystem" onClick={scrollToTop}>
                Ecosystem
              </Link>
            </li>
            {/* <li>
              <Link to="/" onClick={scrollToTop}>
                Blog
              </Link>
            </li> */}
            <li>
              <Link to="/about" onClick={scrollToTop}>
                About Us
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={scrollToTop}>
                Contact
              </Link>
            </li>
          </ul>
        </Col>
        <Col lg={2} md={4} xs={6}>
          <h5>Legal</h5>
          <ul>
            <li>
              <Link to="/terms-and-conditions" onClick={scrollToTop}>
                Terms Conditions
              </Link>
            </li>
            <li>
              <Link to="/privacy-policy" onClick={scrollToTop}>
                Privacy Policy
              </Link>
            </li>
            <li>
              <a
                href="https://docs.pulseworld.com"
                target="_blank"
                rel="noreferrer"
              >
                Docs
              </a>
            </li>
            <li>
              <a
                href="https://docs.pulseworld.com"
                target="_blank"
                rel="noreferrer"
              >
                White Paper
              </a>
            </li>
          </ul>
        </Col>
        <Col lg={3} md={4} xs={12}>
          <h5>Social Media</h5>
          <div className={Style.icons}>
            <a
              href="https://www.facebook.com/pulsesocials"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookF className={Style.facebookIcon} />
            </a>
            <a
              href="https://www.instagram.com/pulsesocials/"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram className={Style.instaIcon} />
            </a>
            <a
              href="https://twitter.com/pulsesocials"
              target="_blank"
              rel="noreferrer"
            >
              <FaTwitter className={Style.twitterIcon} />
            </a>
            <a
              href="https://www.youtube.com/channel/UCayT_og3slWPOF5jNvPTbzA"
              target="_blank"
              rel="noreferrer"
            >
              <FaYoutube className={Style.youtubeIcon} />
            </a>
            <a
              href="https://www.linkedin.com/company/pulsesocialsofficial/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedin className={Style.linKedinIcon} />
            </a>
          </div>
        </Col>
      </Row>
      <div className={Style.separator}></div>
      <div className={Style.footerBottom}>
        <h6>
          <span className={Style.year}>© {year}</span> PULSE. All Right Reserved
        </h6>
      </div>
    </Container>
  );
};

export default Footer;
