import React from "react";
//Components
import { Container, Row, Col } from "@hybris-software/ui-kit";
//images
import chain from "../../../../Assets/icons/chain.svg";
import clock from "../../../../Assets/icons/clock.svg";
import strock from "../../../../Assets/icons/stroke.svg";
import envelop from "../../../../Assets/icons/envelop.svg";
//Sytles
import Style from "./CircleServices.module.css";

const CircleServices = () => {
  return (
    <Container>
      <Row>
        <h3 className={Style.heading}>Values driving Pulse World Ecosystem</h3>
        <Col lg={4}>
          <div className={Style.leftPart}>
            <div className={Style.leftPartUp}>
              <img src={envelop} alt="" />
              <h3>Connecting People</h3>
              <p>
                A place in the Metaverse to meet and network with industry
                professionals or simple enthusiasts with whom to share the
                pleasures of life.
              </p>
            </div>
            <div className={Style.leftPartDown}>
              <img src={clock} alt="" />
              <h3>Power of Tokenomics</h3>
              <p>
                XPL is the native and utility token of Pulse Metaverse. The main
                purpose of the XPL is to allow users to govern and earn rewards
                the growth of the metaverse.
              </p>
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className={Style.middlePart}>
            <div className={Style.mainCircle}></div>
          </div>
        </Col>
        <Col lg={4}>
          <div className={Style.rightPart}>
            <div className={Style.rightPartUp}>
              <img src={strock} alt="" />
              <h3>Creating Value</h3>
              <p>
                By harnessing the power of community and making the most of all
                the opportunities brought to bear by the metaverse and
                blockchain technology.
              </p>
            </div>
            <div className={Style.rightPartDown}>
              <img src={chain} alt="" />
              <h3>Being Disruptive</h3>
              <p>
                Compared to what we are used to, Pulse opens up a unique range
                of possibilities, with paths to economic and intellectual growth
                that no one has been able to offer before.
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CircleServices;
