import React from "react";
//Components

//images
import ball from "../../../../Assets/images/ball.webp";
//Sytles
import Style from "./HeroCenter.module.css";

const HeroCenter = () => {
  return (
    <div className={Style.subContainer}>
      <div className={Style.textContainer}>
        <h2>CONNECTING PEOPLE & VALUE</h2>
        <h1>
          PULSE<span className={Style.stroke}> WORLD</span>
        </h1>
        <p>
          Welcome to Pulse World, a revolutionary metaverse platform allowing
          users to access all the benefit of blockchain technology through DeFi,
          GameFi, SocialFi, Web3, NFTs, and putting them under one big world.
        </p>
      </div>
      <div className={Style.image}>
        <img src={ball} alt="" />
      </div>
    </div>
  );
};

export default HeroCenter;
