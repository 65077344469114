import React from "react";
//Components
//libraires
import { Container } from "@hybris-software/ui-kit";
//Style
import Style from "./BodyTerm.module.css";
const BodyTerm = () => {
  return (
    <Container>
      <div className={Style.subContainer}>
        <div className={Style.info}>
          <span>
            These Terms of Use (the “Terms”), along with the Privacy Policy and
            Risk Disclaimer govern the user (“You”, “Your”, “User”) access to
            and use of all parts of the website(s), platform(s) and
            application(s) branded as Pulse World (“Pulse”, “We”, “Our”, “Us”)
            and referring to the domain [app.pulseworld.com] (the “Platform”) and all
            documents, data, materials or other information made available on
            the Platform.  Your access to and use of the Platform is conditioned
            on your acceptance of and compliance with these Terms. These Terms
            apply to all visitors, users and others who access or use the
            Platform.  You represent that You are lawfully bound by these Terms
            and Privacy Policy as published on the Platform at the time of each
            use. Pulse World reserves the right to update and modify the Terms
            at any time without notice. Your continued access to or use of the
            Platform and all its offered services (“Services”) following the
            posting of revised terms means that You accept and agree to any
            revised terms. These Terms may be made available in several
            languages; all versions are legally binding, but in the event of
            inconsistency between the English version and a translated version,
            the English version prevails.
          </span>
        </div>
        <h3>1. COMPLIANCE WITH LAW </h3>
        <p>
          The use of the Platform and its content is voluntary and falls under
          the responsibility of the User. The Services are intended solely for
          Users who are at least eighteen (18) years old and who satisfy the
          criteria described in these Terms. The User must warrant that he/she:
          (i) as an individual, legal person, or other organization, have full
          legal capacity and sufficient authorizations to enter into these
          Terms; (ii) have not been previously suspended or removed from using
          Our Services; (iii) are not located in, or a citizen or resident of
          the United States; and (iv) is not a legal or natural person with US
          citizenship, domicile or tax liability in the USA or according to the
          sanction lists of OFAC, the United Nations, the European Union, the
          United Kingdom, Austria, Switzerland, Liechtenstein or other sanction
          lists / Some Services may not be available in certain jurisdictions or
          regions or to certain Users. We reserve the right to change, modify or
          impose additional restrictions at Our discretion at any time.
        </p>
        <h3>2. SERVICES ACCESS </h3>
        <p>
          Access to the Services is granted through Our Platform. Certain
          Services or parts of the Platform may be accessible only by agreeing
          to the terms and conditions and privacy policies of third-party
          affiliates, partners and agents (“Third Party Partners”). We strongly
          advise You to read the Terms before applying for the Services.  We
          reserve the right to change, suspend, or discontinue any aspect of the
          Services at any time and in any jurisdiction, including hours of
          operation or availability of any feature, without notice and without
          liability. We may decline to process any order and may limit or
          suspend Your use of one or more Services at any time, in our sole
          discretion. Suspension of your use of any of the Services will not
          affect Your rights and obligations pursuant to these Terms. We may, in
          Our sole discretion, decline to process orders if (i) We believe the
          transaction is suspicious; (ii) the transaction may involve fraud or
          misconduct; (iii) it violates applicable laws; or (vi) it violates the
          terms of these Terms. Where permitted by law, We will notify You by
          the end of the business day if We have suspended processing Your
          orders and, if possible, provide Our reasons for doing so and anything
          You can do to correct any errors leading to the stoppage.  You must
          not access without authority, penetrate, interfere with, damage or
          disrupt (or attempt to do any of the same) any part of the Services or
          its security measures, any servers, other equipment or networks
          connected to the Services or on which it is stored or any software
          used in the provision of the Services, including in each case by
          transmitting any worms, computer viruses, malware, logic bombs, Trojan
          horses, spyware, harmful components or any other software, code or
          data of a corrupt, destructive, malicious or disruptive nature (the
          “Viruses”). You also agree to take steps to ensure that the hardware
          and software that You employ to access the Services does not introduce
          any form of computer Viruses or similar item into the Service and
          agree to indemnify Us for any loss that it may suffer as a result of
          such introduction.
        </p>
        <h3>3. CONDITIONS OF USE </h3>
        <p>
          You may not perform any act or may fall under any of the followings in
          relation to the Services. <br />
          i. Any act that is illegal or otherwise violate applicable law, or any
          other act that violates rules that are generally observed on the
          internet.
          <br />
          ii. Any act that causes disadvantage, inconvenience, discomfort, or
          damage to The Platform or a third party.
          <br />
          iii. Any act that re-provides the Services to a third party without
          the permission of The Platform, regardless of whether it is done for a
          fee or free of charge. iv. Any act that slanders or defames The
          Platform or a third party or that damages the honour, trust, privacy,
          and other moral interests of The Platform or a third party, or an act
          that infringes upon human rights or endorses, invites, cooperates
          with, encourages, or incites infringement on human rights.
          <br /> v. Any act that infringes upon the Intellectual Property
          Rights, etc., of The Platform or a third party. <br />
          vi. Any act that deceives, misleads, defrauds, phishes, or commits or
          attempts to commit identity theft.
          <br />
          vii. Any act that violates any person’s rights of privacy or
          publicity.
          <br /> viii. Any act of harming The Platform or a third party such as
          by using viruses, spyware or malware, worms, trojan horses, time bombs
          or any other such malicious codes or instructions.
          <br /> ix. Any act of gathering, duplicating, altering, or deleting
          the information of a third party that can be accessed from the
          Services in excess of the
          <br />
          manner of use anticipated by the Services. <br />
          x. Any act of sending, posting, etc., message content by e-mail, etc.,
          that will cause unpleasant feelings by the receiver (this includes,
          but is not limited to, specified email and unsolicited email).
          <br /> xi. Any act of wrongfully surveying, gathering, using,
          disclosing, or providing personal information or privacy information
          without the clear consent of the information’s owner. <br />
          xii. Any act that imposes an excessive burden on or interferes with
          the Services’ operation.
          <br /> xiii. Any act of disintegrating, disassembling, decompiling, or
          reverse engineering a program or application included in the Services,
          or an act of deciphering a source code by any other method. <br />
          xiv. Any upload, download, transmit, display, or grant access to
          content that includes graphic depictions of sexuality or violence.{" "}
          <br />
          xv. Any act of collecting or harvesting personally identifiable
          information without permission. This includes, but is not limited to,
          account names and email addresses.
          <br /> xvi. Any act of using the Services for a purpose that differs
          from the Services’ purpose of use, or an act of using the Content in
          excess of the manner of use anticipated by the Services (including
          such acts as duplicating, sending, posting, and alteration).
          <br /> xvii. Any other act that The Platform deems reasonably
          inappropriate.
        </p>
        <h3>4. CONDITIONS OF USE </h3>
        <p>
          The Platform shall offer different Services to its Users that may
          generate rewards upon participation and/or successful involvement. We
          cannot guarantee that the rewards generated are fixed, guaranteed
          and/or matching Your expectations. You acknowledge that there are
          various risks associated with Digital Assets including the volatility
          that may cause the price of Digital Assets to fluctuate along with
          other risks associated with Our Platform operating with any delays,
          errors and/or malfunctions. Our Services may include ands are not
          restricted to the below: <br /> a) Digital Asset Trading <br /> b)
          Online Casino <br /> c) Music Streaming <br /> d) Education/Knowledge
          <br />
          e) Meeting Rooms Sessions
        </p>
        <h3>5. MEMBERSHIP FEES </h3>
        <p>
          Upon using some Services on our Platform, you may need to apply for a
          Membership Subscription, and make a monthly payment that shall be
          referred to Membership Subscription Fees in order to cover your use of
          such services (“Select Services”). Membership Subscription Fees shall
          be payable on a monthly basis. Upon the termination of the membership
          of Select Services, you may choose to renew your Membership
          Subscription for an additional period of one (1) month. You are
          required to remain notified regarding the termination date of your
          membership if you wish to renew your Membership Subscription and
          continue using Select Services.
        </p>
        <h3>6. LINKS TO OTHER WEBSITES</h3>
        <p>
          The Platform may contain links to third-party (including, but not
          limited to, Third Party Partners) websites or services that are not
          owned or controlled by Us.  We have no control over, and We assume no
          responsibility for, the information, content, privacy policies, or
          practices of any third-party websites or services. You further
          acknowledge and agree that We shall not be responsible or liable,
          directly or indirectly, for any damage or loss caused or alleged to be
          caused by or in connection with use of or reliance on any such
          information, content, goods or services available on or through any
          such websites or services.  The risk of injury rests entirely with
          You. Links from the Platform to other websites do not constitute an
          endorsement from Us. It is your responsibility to evaluate the content
          and usefulness of information obtained from other websites.
        </p>
        <h3>7. DISCLAIMERS</h3>
        <h2 style={{ textDecoration: "underline", marginBottom: "20px" }}>
          THE PLATFORM PROVIDED IN “AS IS”, FOR USE AT YOUR OWN RISK, AND
          WITHOUT WARRANTIES OF ANY KIND.
        </h2>
        <p>
          There is a risk that We may be temporarily or permanently not able to
          provide access to the Platform and thus also to the Services. The
          Platform is provided “as is” and “as available”. We and our
          third-party partners, expressly disclaim all representations or
          warranties of any kind, whether express, implied, statutory, or
          otherwise about the Platform and Services, including, without
          limitation, any implied warranties of merchantability, fitness for a
          particular purpose, non-infringement or course of performance.  We do
          not warrant that <br />
          (i) the Platform will function uninterrupted, secure or available at
          any particular time or location, or will be error-free or free of
          harmful components;
          <br /> (ii) any errors or defects will be corrected; <br />
          (iii) the Platform is free of viruses or other harmful components;
          <br /> (iv) any content and data, including Your data, will be secure
          or not otherwise lost or damaged; <br />
          (v) the results that may be obtained from the use of the Services will
          be accurate or reliable; or
          <br /> (vi) the results of using the Platform will meet Your
          requirements or expectations.
        </p>
        <h3>8. ASSUMPTION OF RISK</h3>
        <p>
          a. Digital Asset and Blockchain Risks By accessing or using our
          Platform and/or Services, You are voluntarily choosing to engage in
          sophisticated and risky asset exchanges and transactions. You are
          further acknowledging that You are aware of the many risks associated
          with the use of these Services and with engaging in transactions in
          cryptocurrencies, including, but not limited to, risks of financial
          loss, technology glitches (including, but not limited to, problems
          with the blockchain technology), and hacking. We work hard to provide
          state-of-the-art systems and security. Nonetheless, certain issues and
          risks are unavoidable, and if such issues or problems arise in
          connection with Your use of Our Platform, including technical
          difficulties with depositing or trading cryptocurrencies, it may take
          days, weeks, or months to resolve, and some issues may not be resolved
          at all. By agreeing to these Terms, You acknowledge that We are not
          responsible for the aforementioned risks, and You voluntarily assume
          and accept such risks in deciding to engage in cryptocurrency
          transactions on Our Platform.  You assume the risks of engaging in
          transactions that rely on smart contracts and other experimental
          technology. Transactions may rely on smart contracts stored on various
          blockchains, cryptographic tokens generated by the smart contracts,
          and other nascent software, applications and systems that interact
          with blockchain-based networks. These technologies are experimental,
          speculative, inherently risky, and subject to change. Among other
          risks, bugs, malfunctions, cyberattacks, or changes to the applicable
          blockchain (e.g., forks) could disrupt these technologies and even
          result in a total loss of crypto assets, their market value, or
          digital funds. You are solely responsible for the safekeeping of the
          private key associated with the blockchain address used to interact
          with the Platform. We assume no liability or responsibility for any
          such risks. If You are not comfortable assuming these risks, You
          should not access or engage in transactions using blockchain-based
          technology. You agree to the automated collection and disbursement of
          proceeds by smart contracts. You acknowledge and agree that all
          transactions accessed through the Services will be automatically
          processed using one or more blockchain-based smart contracts. By
          engaging in transactions using the Services, You acknowledge and
          consent to the automatic processing of all transactions in connection
          with using the Services. You further acknowledge and agree that the
          applicable smart contract will dictate how the funds of a transaction
          and ownership of crypto assets are distributed. You acknowledge the
          risks of using the Services. You bear sole responsibility for
          evaluating the Services before using them, and all transactions
          accessed through the Services are irreversible, final, and without
          refunds. The Services may be disabled, disrupted or adversely impacted
          as a result of sophisticated cyber-attacks, surges in activity,
          computer viruses, and/or other operational or technical challenges,
          among other things. We disclaim any ongoing obligation to notify You
          of all of the potential risks of using and accessing our Services. You
          agree to (defined below) accept these risks and agree that You will
          not seek to hold Us responsible for any consequent losses. You are
          solely responsible for the security of Your wallet. You understand and
          agree that You are solely responsible for maintaining the security of
          Your wallet. Any unauthorized access to Your wallet by third parties
          could result in the loss or theft of any crypto asset, or any funds
          held in your wallet (s). You understand and agree that We have no
          involvement in, and You will not hold us responsible for managing and
          maintaining the security of Your wallet. You further understand and
          agree that We are not responsible, and You will not hold us
          accountable, for any unauthorized access to Your wallet. It is Your
          responsibility to monitor Your Wallet. b. Gambling Risks By accessing
          any betting, gambling and/or casino (“Gambling”) Services on our
          Platform, you acknowledge that there is a high risk of losing your
          funds due to the nature of such services. You agree that Your use of
          our Gambling Services is at Your sole option, discretion and risk. You
          agree that there are various risks associated with your mental health
          and emotional stress during Gambling, which can directly or directly
          affect your life.
        </p>
        <h3>9. LIABILITY</h3>
        <p>
          In no event shall We , its Board Members, Employees, Collaboration
          Partner’s or entities of the Pulse World’s group, be liable for any
          indirect, incidental, special, consequential or punitive damages,
          including without limitation, loss of profits, data, use, goodwill, or
          other intangible losses, resulting from (i) Your access to or use of
          or inability to access or use the Platform; (ii) any conduct, content
          of any third party on the Platform; (iii) any content obtained from
          the Platform; and (iv) unauthorized access, use or alteration of Your
          transmissions, content, whether based on warranty, contract, tort
          (including negligence) or (v) any other legal theory, whether or not
          We have been informed of the possibility of such damage, and even if a
          remedy set forth herein is found to have failed of its essential
          purpose.  We strive to ensure but cannot guarantee essentially
          uninterrupted availability of the Platform and the Services and
          error-free transmissions. Access to the Platform or the Services may
          also be suspended or restricted from time to time to allow for repairs
          and maintenance or the introduction of new services or tools.
        </p>
        <h3>10. INDEMNIFICATION</h3>
        <p>
          You agree to fully compensate Us for any and all claims, civil
          responsibilities, damages, expenses and cost, caused by or arising
          from Your use of the Services including, but not limited to,
          exploitation of the Platform’s software error/glitch.
        </p>
        <h3>11. TERMINATION</h3>
        <p>
          These Terms are effective unless and until terminated by either You or
          Us. You may terminate them with Us at any time by ceasing all access
          to the Platform or the Services. If, in Our sole judgment, You fail,
          or We suspect that You have failed, to comply with any term or
          provision of these Terms (including without limitation any provision
          of these Terms), We reserve the right to terminate Our agreement with
          You and deny Your access to the Services. We further reserve the right
          to restrict Your access to the Platform or to stop providing You with
          all or a part of the Services at any time and for no reason,
          including, without limitation, if We reasonably believe: (a) Your use
          of the Services exposes Us to risk or liability; (b) You are using the
          Services for unlawful purposes and/or are performing any illicit or
          suspicious transactions; or (c) it is not commercially viable to
          continue providing You with Our Services. All of these are in addition
          to any other rights and remedies that may be available to Us, whether
          in equity or at law, all of which We expressly reserve.
        </p>
        <h3>12. GENERAL PROVISION &amp; APPLICABLE LAW</h3>
        <p>
          You may not assign, sub-license or otherwise transfer any of Your
          rights and/or obligations under these Terms to any third party. You
          acknowledge that We may assign, sub-license or otherwise transfer any
          of Your rights and/or obligations under these Terms to any third party
          at any time.  We will have no liability to You for any failure or
          delay in performing any of Our obligations under these Terms to the
          extent that such failure or delay is caused or contributed to by You
          or by an event or circumstance beyond Our reasonable control.  These
          Terms (together with the Privacy Policy) contain the entire agreement
          and understanding of the parties relating to the subject matter of the
          agreement between Us and supersedes all prior agreements,
          understandings or arrangements (both oral and written) relating to the
          same.  If any provision of these Terms is found to be invalid for any
          reason, the invalidity of that provision will not affect the remaining
          provisions of these Terms, which will remain in full force and
          effect.  Failure by either You or Us to exercise any right or remedy
          under these Terms does not constitute a waiver of that right or
          remedy. These Terms are governed by and shall be construed in
          accordance with the laws of British Virgin Islands.
        </p>
        <h3>13. MODIFICATIONS</h3>
        <p>
          We reserve the right, in Our sole discretion, to modify these Terms
          from time to time. If We make any modifications, We will notify You by
          updating the date at the top of this Terms and by maintaining a
          current version of the Terms. All modifications will be effective when
          they are posted, and Your continued accessing or use of the Platform
          will serve as confirmation of Your acceptance of those modifications.
          If You do not agree with any modifications to these Terms, You must
          immediately stop accessing and using the Platform.
        </p>
        <h3>14. PRIVACY POLICY</h3>
        <p>
          We care about Your data protection, its security and confidentiality.
          Please refer to Our Privacy Policy to find information on how We will
          process Your personal data when You visit or use Our Platform or
          contact Us.
        </p>
        <h3>15. CONTACT US</h3>
        <p>
          Any User who is dissatisfied with Our Services, has found a
          deficiency, or identified dishonesty, should contact Our customer
          Support via Our email info@pulseworld.com. We will do Our best to
          resolve Your request as soon as possible, but this may not always be
          possible. We will review and reply to all admissible complaints within
          the timeframe that We have specified in the acknowledgement sent by
          Us.
        </p>
      </div>
    </Container>
  );
};

export default BodyTerm;
