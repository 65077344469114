import React from "react";

//Components
import { Container, Row, Col } from "@hybris-software/ui-kit";

//Styles
import Style from "./NewWay.module.css";

const data = [
  {
    id: 1,
    title: "Catch the value",
    textContent:
      "All the fee generated from rooms and services offered form the Metaverse will be used to buyback the XPL from the market.",

    BGColor: "#6F5BEB",
  },
  {
    id: 2,
    title: "Get NFTs",
    textContent:
      "With XPL tokens users can get NFTs that will be used to access a lot of services and benefits inside the Metaverse.",

    BGColor: "#EBB15B",
  },
  {
    id: 3,
    title: "Burning System",
    textContent:
      "Part of the fee generated from the metaverse are used to buyback and burn XPL token.",

    BGColor: "#89EB5B",
  },
  {
    id: 4,
    title: "Loyalty Level",
    textContent:
      "The Loyalty Level allows you to both increase your benefit within the entire Pulse World Ecosystem.",

    BGColor: "#43DBFD",
  },
];

const NewWay = () => {
  return (
    <Container>
      <div className={Style.heading}>
        <h2>XPL: Trade in a New Way in the Meta-World</h2>
        <p>
          XPL is the native and utility token of Pulse Metaverse. The main
          purpose of the XPL is to allow users to govern and earn rewards the
          growth of the metaverse.
        </p>
      </div>
      <Row>
        <Col xl={6} className={Style.left}></Col>
        <Col xl={6} className={Style.right}>
          <div className={Style.cards}>
            {data.map((el, index) => {
              return (
                <div className={Style.cardContainer} key={index}>
                  <div
                    className={Style.color}
                    style={{ background: el.BGColor }}
                  ></div>

                  <div className={Style.card}>
                    <h5>{el.title}</h5>
                    <p>{el.textContent}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NewWay;
