import { Container } from "@hybris-software/ui-kit";
import React, { useState, useEffect } from "react";

//Components
import From from "./Components/Form/Form";
import HeroCenter from "./Components/HeroCenter/HeroCenter";
import Loader from "../Loader/Loader";


//Styles
import Style from "./ContactView.module.css";

const ContanctView = () => {

  const [loading, setLoading] = useState(true);

  if(loading){
    document.body.style.overflow = "hidden";
  }else{
    document.body.style.overflow = "unset";
  }
  
    useEffect(() => {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }, []);

  document.title = "Pulse World | Contact";
  return (
    <>
      {loading && <Loader />}

      <Container className={Style.container}>
        <section className={Style.heroCenter}>
          <HeroCenter />
        </section>
      </Container>
      <section className={Style.form}>
        <From />
      </section>
    </>
  );
};

export default ContanctView;
