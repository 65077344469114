import React from "react";

//Components
import { Container } from "@hybris-software/ui-kit";

//Styles
import Style from "./Evolution.module.css";

const Evolution = () => {
  return (
    <Container className={Style.mainContainer}>
      <div className={Style.headings}>
        <h2>PULSE WORLD VISION & MISSION</h2>
        <p>
          Our Vision is to shape the future with innovation by connecting people
          through a virtual world that is based on creating economic opportunity
          for every participant as we believe strongly in the power of
          communities and financial freedom.
        </p>

        <p>
          Our Mission is a social mission, first and foremost, enabling
          individuals to increase their value by harnessing the power of
          community and making the most of all the opportunities brought to bear
          by the metaverse and blockchain technology.
        </p>
      </div>
    </Container>
  );
};

export default Evolution;
