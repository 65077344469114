import React, { useState } from "react";

//Components
import { Container, Button, Col, Row } from "@hybris-software/ui-kit";

//Styles
import Style from "./Values.module.css";

const data = [
  {
    tagText: "Our vision",
    color: "#eb5be5",
    title: "Vision",
  },
  {
    tagText: "Our Mission",
    color: "#6F5BEB",
    title: "Mission",
  },
];

const Values = () => {
  const [showMission, setShowMission] = useState("none");
  const [showVision, setShowVision] = useState("flex");

  const displayMission = () => {
    setShowMission("flex");
    setShowVision("none");
  };
  const displayVision = () => {
    setShowMission("none");
    setShowVision("flex");
  };

  return (
    <Container>
      <Row className={Style.mainContainer}>
        <Col sm={6} className={Style.left}>
          {data.map((el, index) => {
            return (
              <div
                onClick={index === 1 ? displayMission : displayVision}
                className={Style.cardContainer}
                key={index}
              >
                <div
                  className={Style.color}
                  style={{ background: el.color }}
                ></div>
                <div className={Style.card}>
                  <div className={Style.tag}>
                    <p style={{ color: el.color }}>{el.tagText}</p>
                  </div>
                  <h4>pulse world</h4>
                  <h2>{el.title}</h2>
                </div>
              </div>
            );
          })}
        </Col>

        <Col sm={6}>
          <div style={{ display: showVision }} className={Style.vision}>
            <h4>Our Vision</h4>
            <h2>Creating Opportunities</h2>
            <p>
              Our Vision is to shape the future with innovation by connecting
              people through a virtual world that is based on creating economic
              opportunity for every participant as we believe strongly in the
              power of communities and financial freedom.
            </p>
            <a
              href="https://docs.pulseworld.com"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Know More</Button>
            </a>
          </div>

          <div style={{ display: showMission }} className={Style.mission}>
            <h4>Our Mission</h4>
            <h2>Generating Value</h2>
            <p>
              Our Mission is a social Mission, first and foremost, enabling
              individuals to increase their value by harnessing the power of
              community and making the most of all the opportunities brought to
              bear by the metaverse ad blockchain technology.
            </p>
            <a
              href="https://docs.pulseworld.com"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Know More</Button>
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Values;
