import React, { useState, useEffect } from "react";

// Libraries
import { useNavigate } from "react-router-dom";

///Components
import Loader from "../Loader/Loader";

// Images
import NotFound from "../../Assets/images/404.png";
import PointRight from "../../Assets/images/point-right.png";
import PointLeft from "../../Assets/images/point-left.png";
import LogoPulseFull from "../../Assets/icons/logo.svg";

// Styles
import Style from "./Page404.module.css";

const Page404 = () => {

  const [loading, setLoading] = useState(true);

  if(loading){
    document.body.style.overflow = "hidden";
  }else{
    document.body.style.overflow = "unset";
  }
  
    useEffect(() => {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }, []);

  const navigate = useNavigate();
  document.title = "Pulse World | 404 ";
  return (
    <>
    {loading && <Loader />}
    <section className={Style.page}>
      <img
        src={LogoPulseFull}
        alt=""
        className={Style.logo}
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/");
        }}
      />
      <img src={PointRight} alt="" className={Style.pointRight} />
      <img src={PointLeft} alt="" className={Style.pointLeft} />
      <div className={Style.center}>
        <img src={NotFound} alt="" />
        <div className={Style.text}>
          <h1>Page not found</h1>
          <h4>Sorry we can’t found the page that you’re looking for</h4>
        </div>
      </div>
    </section>
    </>
  );
};

export default Page404;
