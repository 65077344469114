import { Container } from "@hybris-software/ui-kit";
import React, { useState, useEffect } from "react";

//Components
import Loader from "../Loader/Loader";
import HeroCenter from "./Components/HeroCenter/HeroCenter";
import Team from "./Components/Team/Team";
import Values from "./Components/Values/Values";
import ContactUs from "../../Components/UIKit/ContactUs/ContactUs";
import Roadmap from "./Components/Roadmap/Roadmap";
// import FAQ from "./Components/FAQ/FAQ";
// import Partners from "./Components/Partners/Partners";

//Styles
import Style from "./AboutView.module.css";
import AboutPulse from "./Components/AboutPulse/AboutPulse";

// const faqData = [
//   {
//     id: 1,
//     question: "Is it safe to use crypto app?",
//     answer:
//       "Yes, crypto app and Coinbase are safe and use security measures that are industry-standard or above for U.S.-based.",
//   },
//   {
//     id: 2,
//     question: "How do I connect fugu to my crypto wallet?",
//     answer:
//       "Once you've approved a connection request from the app the fugu can send transaction requests to wallet, which is the on your must also manually approve in the Wallet.",
//   },
//   {
//     id: 3,
//     question: "How do you make money with crypto app?",
//     answer:
//       "This is the most common way of earning money from crypto currencies. Investors buy coins such as Bitcoin, Litecoin, and Ethereum, Ripple, and more and wait until their value rises.",
//   },
// ];

const AboutView = () => {

  const [loading, setLoading] = useState(true);

  if(loading){
    document.body.style.overflow = "hidden";
  }else{
    document.body.style.overflow = "unset";
  }
  
    useEffect(() => {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }, []);

  document.title = "Pulse World | About Us";
  return (
    <>
      {loading && <Loader />}
      <Container className={Style.container}>
        <section className={Style.heroCenter}>
          <HeroCenter />
        </section>
      </Container>
      {/* <section className={Style.partners}>
        <Partners />
      </section> */}
      <section className={Style.aboutPulse}>
        <AboutPulse />
      </section>
      <section className={Style.roadmap}>
        <Roadmap />
      </section>
      {/* <section className={Style.team}>
        <Team />
      </section> */}
      <section className={Style.values}>
        <Values />
      </section>
      {/* <section className={Style.faq}>
        <FAQ data={faqData} />
      </section> */}
      <section className={Style.contactUs}>
        <ContactUs />
      </section>
    </>
  );
};

export default AboutView;
