import React from "react";

//Components
import { Container, Row, Col, Button } from "@hybris-software/ui-kit";
import { useNavigate } from "react-router-dom";

//Styles
import Style from "./ContactUs.module.css";

const ContactUs = () => {
  const navigate = useNavigate();
  return (
    <Container className={Style.mainContainer}>
      <div className={Style.dotsRight}></div>
      <div className={Style.dotsLeft}></div>
      <Row className={Style.mainRow}>
        <Col className={Style.left} lg={5}></Col>
        <Col className={Style.right} lg={7}>
          <h3>Connect & Have Fun</h3>
          <p>
            Use Pulse World to connect you to the world by harnessing the
            immense potential provided by the Metaverse: create valuable
            realizations, increase your knowledge and network as well as your
            value, and don't stop having fun-our greatest satisfaction is your
            happiness and growth.
          </p>
          <div className={Style.Button}>
            <Button
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                navigate("/contact");
              }}
            >
              Contact Us
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactUs;
