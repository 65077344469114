import React, { useState } from "react";
//Components
//Libraries
import {
  Container,
  Row,
  Col,
  Button,
  InputField,
} from "@hybris-software/ui-kit/";
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query";
//icons
import Phone from "../../../../Assets/icons/phone.svg";
import Location from "../../../../Assets/icons/location.svg";
import Mail from "../../../../Assets/icons/mail.svg";

//Styles
import Style from "./Form.module.css";
const informations = [
  {
    icon: Mail,
    title: "MAIL US ",
    description: "Email id: info@pulseworld.com",
  },
];

const Form = () => {
  const [formMessage, setFormMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const contactApi = useQuery({
    url: "api/v1/contactform/",
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      setFormMessage(true);
    },
    onError: (error) => {
      console.log(errorMessage, "ERROR1");
      setErrorMessage(true);
    },
  });

  const form = useForm({
    inputs: {
      name: {
        required: true,
      },
      phone: {
        required: true,
      },
      email: {
        required: true,
        nature: "email",
      },
      message: {
        required: true,
      },
    },
  });
  if (formMessage) {
    document.getElementById("form").style.display = "none";
  } else {
    document.body.style.display = "block";
  }

  return (
    <Container>
      <Row>
        <div className={Style.heading}>
          <h3>Say Hello</h3>
          <h2>WE'D PLEASED TO HEAR FROM YOU.</h2>
        </div>

        <Col lg={6}>
          {formMessage && (
            <>
              <h2>Message sent</h2>
            </>
          )}
          <div className={Style.formContiner} id="form">
            <InputField
              className={Style.inputField}
              label="Your Name*"
              {...form.getInputProps("name")}
            />

            <InputField
              className={Style.inputField}
              label="Phone Number*"
              {...form.getInputProps("phone")}
            />

            <InputField
              className={Style.inputField}
              label="Email"
              {...form.getInputProps("email")}
            />

            <InputArea label="Message" {...form.getInputProps("message")} />
            <Button
              style={{ opacity: form.isValid() ? "1" : ".5" }}
              disabled={!form.isValid()}
              onClick={() => {
                contactApi.executeQuery(form.values);
                setErrorMessage(false);
              }}
            >
              Submit
            </Button>
          </div>
        </Col>
        <Col lg={6}>
          <div className={Style.rightSection}>
            <h4>CONTACT INFO</h4>
            {informations.map((info, index) => (
              <div className={Style.titleIcon} key={index}>
                <img src={info.icon} alt="" />
                <div>
                  <h6>{info.title}</h6>
                  <p>{info.description} </p>
                </div>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
const InputArea = ({ value, setValue, label, style }) => {
  return (
    <div className={Style.inputField}>
      <h6>Message</h6>
      <textarea
        rows="8"
        style={style}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
    </div>
  );
};
export default Form;
