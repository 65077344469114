//Components
import Footer from "../../Components/UIKit/Footer/Footer";
import Navbar from "./../../Components/UIKit/Navbar/Navbar";
import Disclaimer from "./../../Components/UIKit/Disclaimer/Disclaimer";

// Libraries
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Alert from "../../Components/UIKit/Alert/Alert";
//Styles
// import Style from "./MainLayout.module.css";

const MainLayout = () => {
  const [disclaimer, setDisclaimer] = useState(true);
  const [alertPopup, setAlertPopup] = useState(false);

  if (disclaimer) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "unset";
  }

  const handleClick = () => {
    setDisclaimer(false);
  };

  return (
    <>
      <main>
        {disclaimer && <Disclaimer handleClick={handleClick} />}
        {alertPopup && <Alert setAlertPopup={setAlertPopup} />}
        <Navbar />
        <Outlet />
        <Footer />
      </main>
    </>
  );
};

export default MainLayout;
