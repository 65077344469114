import React from "react";
//Components
import { Row, Col, Container, Button } from "@hybris-software/ui-kit";

//images
import right from "../../../../Assets/images/secureEarn.png";
//icons
import order from "../../../../Assets/icons/orderIcon.svg";
//Style
import Style from "./SecureEarn.module.css";
const informations = [
  {
    title: "Connecting People",
    description: "A place in the Metaverse to meet and network.",
  },
  {
    title: "Creating Value",
    description:
      "By harnessing the power of community and making the most of all the opportunities brought to bear by the metaverse and blockchain technology.",
  },
  {
    title: "XPL",
    description:
      "XPL is the native and utility token of Pulse Metaverse. The main purpose of the XPL is to allow users to govern and earn rewards the growth of the metaverse.",
  },
];
const SecureEarn = () => {
  return (
    <Container className={Style.mainCotainer}>
      <Row>
        <Col lg={5}>
          <div className={Style.leftContainer}>
            <h2>A Simple Way to Keep in Touch and Grow Value</h2>
            <div className={Style.contentContainer}>
              {informations.map((info, index) => (
                <div key={index} className={Style.content}>
                  <img src={order} alt="" />
                  <div className={Style.information}>
                    <h3>{info.title}</h3>
                    <p>{info.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className={Style.button}>
              <a
                href="https://app.pulseworld.com"
                target="_blank"
                rel="noreferrer"
              >
                <Button>Take me to Pulse Platform</Button>
              </a>
            </div>
          </div>
        </Col>
        <Col lg={7}>
          <div className={Style.imageContainer}>
            <h1>Pulse World</h1>
            <img src={right} alt="Pluse-FI" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SecureEarn;
