import React from "react";

//Components
import { Container, Row, Col } from "@hybris-software/ui-kit";


//Styles
import Style from "./AboutPulse.module.css";

const data = [
  {
    id: 1,
    text: "Visionary & Passionate Team",
  },
  {
    id: 2,
    text: "10+ Years of Experience",
  },
  {
    id: 3,
    text: " DeFi, GameFi SocialFi, Web3",
  },
  {
    id: 4,
    text: "Make the Difference",
  },
];

const AboutPulse = () => {
  return (
    <Container className={Style.mainContainer}>
      <Row
        columnGap={{
          horizontal: {
            lg: 20,
          },
          vertical: {
            xs: 20,
          },
        }}
      >
        <Col xl={5} className={Style.left}>
          <div className={Style.vectorLines}></div>
          <h4>About Us</h4>
          <h2>The Rise of Legends</h2>
          <p>
          We are a team of visionary and passionate professionals, and our company's history from afar takes us far. That's how we landed at Pulse World: after decades of experience in DeFi, GameFi, SocialFi, Web3 and NFT fields, our intent is now to create a true ecosystem, revolutionary and blockchain based, that, different from the traditional systems of metaverse, allows users to find each other and socialize, creating a community of people united not only by interests but also by space and time, for value sharing and growth that is collective and, above all, real.
          </p>
        </Col>
        <Col xl={7} className={Style.right}>
          <div className={Style.meta}>
            <div className={Style.cubeContainer}>
              <div className={Style.cubes}></div>
            </div>
          </div>
        </Col>
      </Row>
      <Row
        className={Style.bottomRow}
        columnGap={{
          horizontal: {
            lg: 20,
          },
          vertical: {
            xs: 20,
          },
        }}
      >
        {data.map((el, index) => {
          return (
            <Col xl={3} sm={6} key={index}>
              <div className={Style.secondRow}>
                <h1>{el.id}</h1>
                <h4>{el.text}</h4>
              </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default AboutPulse;
