import React from "react";
//Components
import { Row, Col } from "@hybris-software/ui-kit";
//Sytles
import Style from "./HeroCenter.module.css";

const HeroCenter = () => {
  return (
    <div className={Style.subContainer}>
      <Row>
        <Col lg={5}></Col>
        <Col lg={7} className={Style.rightCol}>
          <div className={Style.textContainer}>
            <h2>STAND OUT IN THE MARKET</h2>
            <h1>
              PULSE<span className={Style.stroke}> WORLD</span> ECOSYSTEM
            </h1>
            <p>
              Pulse World is guaranteed to be effective because it is based on
              real and scalable elements that distinguish the market, leveraging
              levers that have not yet been tapped by mainstream circuits.
            </p>
          </div>
        </Col>
      </Row>

      <div className={Style.footer}></div>
    </div>
  );
};

export default HeroCenter;
