import React from "react";

//Components
import { Container } from "@hybris-software/ui-kit";

//Styles
import Style from "./CreatingMetaverse.module.css";

const CreatingMetaverse = () => {
  function scrollUpTwo() {
    document.getElementById("secondDiv").classList.add(Style.move);
    document.getElementById("cardOne").style.filter = "blur(4px)";
  }
  function scrollDownTwo() {
    document.getElementById("secondDiv").classList.remove(Style.move);
    document.getElementById("cardOne").style.filter = "blur(0)";
  }
  function scrollUpThree() {
    document.getElementById("thirdDiv").classList.add(Style.move);
    document.getElementById("cardTwo").style.filter = "blur(4px)";
  }
  function scrollDownThree() {
    document.getElementById("thirdDiv").classList.remove(Style.move);
    document.getElementById("cardTwo").style.filter = "blur(0)";
  }

  return (
    <Container className={Style.mainContainer}>
      <div className={Style.dotsRight}></div>
      <div className={Style.dotsLeft}></div>
      <h2 className={Style.heading}>What do you want to do now?</h2>
      <div className={Style.cards}>
        <div
          onMouseEnter={scrollUpTwo}
          onMouseLeave={scrollDownTwo}
          className={Style.singleCardOne}
        >
          <div id="cardOne" className={Style.cardOne}></div>
          <div id="secondDiv" className={Style.textContent}>
            <h5>Embrace The Pulse World Ecosystem</h5>
            <p>
              Learn more about Ecosystem Pulse World and start increasing your
              value now with our immense possibilities in the Metaverse.
            </p>
          </div>
        </div>

        <div
          onMouseEnter={scrollUpThree}
          onMouseLeave={scrollDownThree}
          className={Style.singleCardTwo}
        >
          <div id="cardTwo" className={Style.cardTwo}></div>
          <div id="thirdDiv" className={Style.textContent}>
            <h5>Empower Digital Ownership with NFTs</h5>
            <p>
              Wearable NFTs, Music NFTs, Tickets NFTs: choose the digital
              content you most prefer and start using it in the Metaverse,
              creating value for you.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CreatingMetaverse;
