import React, { useState } from "react";

// Libraries
import { useLocation, Link } from "react-router-dom";
import { Container, Button } from "@hybris-software/ui-kit";

// Utils
import classNames from "../../../Utils/classNames";

// Logo
import Logo from "../../../Assets/icons/logo.svg";

// Styles
import Style from "./Navbar.module.css";

const navbarMenu = {
  items: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Ecosystem",
      path: "/ecosystem",
    },
    {
      name: "About",
      path: "/about",
    },
    {
      name: "Contact",
      path: "/contact",
    },
  ],
};
const Navbar = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  return (
    <header className={Style.navBar}>
      <Container className={Style.mainContainer}>
        <div className={Style.content}>
          {/* Logo */}
          <Link to="/" className={Style.navBarLogo}>
            <img alt="logo" src={Logo} />
          </Link>
          {/* Menu */}
          <NavBarMenu navbarMenu={navbarMenu} location={location} />
          <div className={Style.buttons} style={{ width: 108 }}>
            {/* <Button
              onClick={() => window.open('https://backoffice.omegapro.world/')}
            >
              Login
            </Button> */}
          </div>

          {/* NavbarMenu Mobile */}
          <div className={Style.navBarIcon}>
            <div
              className={classNames(
                Style.navBarIconMobile,
                open ? Style.active : Style.notActive
              )}
              onClick={() => {
                setOpen(!open);
                if (open) {
                  document.body.style.overflow = 'unset';
                } else {
                  document.body.style.overflow = 'hidden';
                }
              }}
            >
              <span className={Style.iconPartOne}></span>
              <span className={Style.iconPartTwo}></span>
              <span className={Style.iconPartThree}></span>
            </div>
          </div>

          <MobileMenu
            navbarMenu={navbarMenu}
            location={location}
            open={open}
            setOpen={setOpen}
          />
        </div>
      </Container>
    </header>
  );
};

export default Navbar;

const NavBarMenu = ({ navbarMenu, location }) => {
  return (
    <nav>
      <ul className={Style.navBarMenu}>
        {navbarMenu.items.map((item, index) => {
          return (
            <li
              key={index}
              className={classNames(
                location.pathname === item.path
                  ? Style.navBarMenuItemActive
                  : Style.navBarMenuItem
              )}
            >
              <Link
                className={classNames(
                  Style.navBarMenuLink,
                  location.pathname === item.path && Style.navBarMenuLinkActive
                )}
                to={item.path}
              >
                {item.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

const MobileMenu = ({ navbarMenu, location, open, setOpen }) => {
  return (
    <div className={classNames(Style.mobileMenu, open && Style.mobileMenuOpen)}>
      <nav>
        <ul className={Style.navBarMenuMobile}>
          {navbarMenu.items.map((item, index) => {
            return (
              <li
                key={index}
                className={classNames(
                  location.pathname === item.path
                    ? Style.navBarMenuItemActive
                    : Style.navBarMenuItem
                )}
              >
                <Link
                  className={classNames(
                    Style.navBarMenuLink,
                    location.pathname === item.path &&
                    Style.navBarMenuLinkActive
                  )}
                  onClick={() => {
                    setOpen(false);
                    document.body.classList.remove(Style.bodyScrollDisabled);
                  }}
                  to={item.path}
                >
                  {item.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};
