import React from "react";
//Components
import { Row, Col, Container, Button } from "@hybris-software/ui-kit";

//images
import left from "../../../../Assets/images/pluseFi.png";
//Style
import Style from "./PulseFi.module.css";
const PulseFi = () => {
  return (
    <Container className={Style.mainCotainer}>
      <div className={Style.footer}></div>
      <Row>
        <Col lg={5} className={Style.colOne}>
          <div className={Style.imageContainer}>
            <img src={left} alt="Pluse-FI" />
          </div>
        </Col>
        <Col lg={7}>
          <div className={Style.rightContainer}>
            <h1>Pulse World</h1>
            <h2>A world of opportunities</h2>
            <p>
              Our intent is to create a true ecosystem, revolutionary and
              blockchain based, that, different from the traditional systems of
              metaverse, allows users to find each other and socialize, creating
              a community of people united not only by interests but also by
              space and time, for value sharing and growth that is collective
              and, above all, real
            </p>
            <a
              href="https://docs.pulseworld.com"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Learn More</Button>
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PulseFi;
