import React from "react";

//Components
import { Container, Button } from "@hybris-software/ui-kit";

//Styles
import Style from "./Unleash.module.css";

const Unleash = () => {
  return (
    <Container className={Style.mainContainer}>
      <h1>Unleash the world of METAVERSE</h1>
      <p>
        Pulse World is a social and omnichannel experience guaranteed by the
        Metaverse, and it is one of a kind platform that is able to offer
        multiple distinct services and rewards, through the development of
        industry experts partners and collaborators.
      </p>
      <a href="https://docs.pulseworld.com" target="_blank" rel="noreferrer">
        <Button>Learn More</Button>
      </a>
    </Container>
  );
};

export default Unleash;
