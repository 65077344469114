import React from "react";
//Components
import { Row, Col } from "@hybris-software/ui-kit";
//images
import VR from "../../../../Assets/images/VrGlasses.png";
//Sytles
import Style from "./HeroCenter.module.css";

const HeroCenter = () => {
  return (
    <div className={Style.subContainer}>
      <Row className={Style.imageBackground}>
        <Col lg={5} className={Style.leftCol}>
          <div className={Style.textContainer}>
            <h1>
              CONTAC<span className={Style.stroke}>T Page</span>
            </h1>
            <p>
              Your satisfaction is our greatest satisfaction: therefore, do not
              hesitate to contact us whenever you feel it is necessary. Our team
              of experts is always ready, 7/24, to provide you with the answers
              you are looking for.
            </p>
          </div>
        </Col>
        <Col lg={7} className={Style.rightCol}>
          <div className={Style.image}>
            <img src={VR} alt="" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HeroCenter;
